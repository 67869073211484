import React, { useState } from "react";

// 상수로 API Key 선언
const OPENAI_API_KEY = "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"; // 여기에 OpenAI API 키를 입력

const FineTuneComponent = () => {
  const [jsonlData, setJsonlData] = useState("");
  const [response, setResponse] = useState("");

  const handleSubmit = async () => {
    try {
      // Step 1: Upload JSONL file
      const file = new Blob([jsonlData], { type: "application/jsonl" });
      const formData = new FormData();
      formData.append("file", file);
      formData.append("purpose", "fine-tune");

      const uploadResponse = await fetch("https://api.openai.com/v1/files", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${OPENAI_API_KEY}`,
        },
        body: formData,
      });

      const uploadResult = await uploadResponse.json();

      if (uploadResponse.ok) {
        const fileId = uploadResult.id;

        // Step 2: Start fine-tuning
        const fineTuneResponse = await fetch(
          "https://api.openai.com/v1/fine-tunes",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${OPENAI_API_KEY}`,
            },
            body: JSON.stringify({
              training_file: fileId,
              model: "davinci",
            }),
          }
        );

        const fineTuneResult = await fineTuneResponse.json();
        setResponse(JSON.stringify(fineTuneResult, null, 2));
      } else {
        setResponse(
          `File upload failed: ${JSON.stringify(uploadResult, null, 2)}`
        );
      }
    } catch (error) {
      setResponse(`Error: ${error.message}`);
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <h2>Fine-Tune 모델 호출</h2>
      <div>
        <label>JSONL 데이터:</label>
        <textarea
          value={jsonlData}
          onChange={(e) => setJsonlData(e.target.value)}
          placeholder="JSONL 형식 데이터 입력"
          rows="10"
          style={{ width: "100%", marginBottom: "10px" }}
        />
      </div>
      <button onClick={handleSubmit}>Fine-Tune 실행</button>
      <div style={{ marginTop: "20px" }}>
        <h3>응답 결과:</h3>
        <pre>{response}</pre>
      </div>
    </div>
  );
};

export default FineTuneComponent;
