import {BrowserRouter, Routes,Route,Link} from 'react-router-dom'
import {Navbar,Container, Nav} from 'react-bootstrap';
import './App.css';
import './bootstrap-4.0.0/css/bootstrap.min.css';
import MainPage from './routePage/MainPage.js'
import ReminiChat from './routePage/ReminiChat.js'
import ReminiAudio01 from './routePage/ReminiAudio01.js'
import ReminiAudio02 from './routePage/ReminiAudio02.js'
import ReminiFineTunning from './routePage/ReminiFineTunning.js'

function App() {
  
  return (
    <div className="App">
     <BrowserRouter>
        <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <Navbar.Brand as={Link} to="/">Remini App</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/">Home</Nav.Link>
              <Nav.Link as={Link} to="/reminiTextchat">ReminiTextchat</Nav.Link>
              <Nav.Link as={Link} to="/reminiaudio01">ReminiAudi01</Nav.Link>
              <Nav.Link as={Link} to="/reminiaudio02">ReminiAudi02</Nav.Link>
              <Nav.Link as={Link} to="/reminiFineTunning">ReminiFineTunning</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
        </Navbar>

        <Container className="mt-3">
        <Routes>
          <Route path="/" element={<MainPage/>}/>
          <Route path="/reminiTextchat" element={<ReminiChat/>}/>
          <Route path="/reminiaudio01" element={<ReminiAudio01/>}/>
          <Route path="/reminiaudio02" element={<ReminiAudio02/>}/>
          <Route path="/reminiFineTunning" element={<ReminiFineTunning/>}/>
          
          <Route path="*" element={<div>없는 페이지에요</div>}/>
        </Routes>
        </Container>
      </BrowserRouter>

    </div>
  );
}

export default App;

